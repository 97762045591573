import React, { VFC } from 'react'
import { Grid } from '@material-ui/core'
import { CheckoutLayout } from '../CheckoutLayout'
import { CheckoutLogin } from '../CheckoutLogin'
import { CheckoutRegistration } from '../CheckoutRegistration'
import * as Styled from './styled'
import { Head } from '../../global/Head'
import { CheckoutGlobalStyle } from '../CheckoutGlobalStyle'

export const CheckoutPage: VFC = () => {
  return (
    <>
      <Head />
      <CheckoutGlobalStyle />
      <CheckoutLayout showNavigationBar>
        <Grid alignItems="center" justifyContent="center" container item xs={12}>
          <Grid item xs={12} md={10}>
            <Styled.LoginContainer>
              <CheckoutLogin />
            </Styled.LoginContainer>
            <Styled.Separator>
              <Styled.SeparatorLine text="OR" />
            </Styled.Separator>
            <Styled.RegistrationContainer>
              <CheckoutRegistration />
            </Styled.RegistrationContainer>
          </Grid>
        </Grid>
      </CheckoutLayout>
    </>
  )
}
