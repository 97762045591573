import styled, { css } from 'styled-components'

export const LoginContainer = styled.div`
  margin-top: 20px;
`

export const Separator = styled.div`
  margin: 30px 0;
`

export const SeparatorLine = styled.hr<{ text: string }>`
  ${({ theme, text }) => css`
    line-height: 1em;
    position: relative;
    outline: 0;
    border: 0;
    color: black;
    text-align: center;
    height: 1.5em;
    &:before {
      content: '';
      background: ${theme.colors.coolGray.cool400};
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      height: 1px;
    }
    &:after {
      content: '${text}';
      position: relative;
      display: inline-block;
      padding: 0 20px;
      line-height: 1.5em;
      color: ${theme.colors.coolGray.cool600};
      font-weight: bold;
      background-color: #ffffff;
    }
  `}
`

export const RegistrationContainer = styled.div`
  margin-top: 20px;
`
