import styled from 'styled-components'
import { Button } from '@material-ui/core'

export const Container = styled.div`
  text-align: center;
`

export const Submit = styled(Button)`
  && {
    max-width: 330px;
  }
`
