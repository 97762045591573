import styled from 'styled-components'
import { Title as RegistrationTitle } from '../../registration-form/RegistrationFormPatient'

export const CheckoutRegistration = styled.div`
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 1px;
`

export const Content = styled.div`
  height: 100%;
  max-width: 450px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Title = styled(RegistrationTitle)`
  text-align: center;
`

export const Container = styled.div`
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 1px;
`

export const FormContainer = styled.div`
  max-width: 525px;
  width: 100%;
`
