import { useAuth0 } from '@auth0/auth0-react'
import React, { useCallback, VFC } from 'react'
import * as Styled from './styled'

export const CheckoutLogin: VFC = () => {
  const { loginWithRedirect } = useAuth0()
  const login = useCallback(() => loginWithRedirect(), [loginWithRedirect])

  return (
    <Styled.Container>
      <Styled.Submit color="primary" fullWidth onClick={login} type="button" variant="contained">
        LOGIN
      </Styled.Submit>
    </Styled.Container>
  )
}
