import React, { useCallback, useEffect, useState, VFC } from 'react'
import { Subscribe } from '@react-rxjs/core'
import { useRegistrationTypes } from '../../../lib/registration'
import {
  RegistrationFormPatient,
  TitleHelper,
} from '../../registration-form/RegistrationFormPatient'
import * as Styled from './styled'
import { RegistrationFormPractitioner } from '../../registration-form/RegistrationFormPractitioner'
import { RegistrationTypeSelector } from '../../registration-form/RegistrationTypeSelector'
import { RegistrationFormStudent } from '../../registration-form/RegistrationFormStudent'
import { useSiteMetadata } from '../../../lib/sanity/hooks/useSiteMetadata'

export const CheckoutRegistration: VFC = () => {
  const { defaultCheckoutRegistrationType } = useSiteMetadata() || {}

  const [submitting, setSubmitting] = useState(false)
  const [showGuestCheckoutTitle, setShowGuestCheckoutTitle] = useState(false)

  const {
    allowPractitioner,
    allowPatient,
    allowPatientZipCode,
    allowStudent,
    selectedType,
    isSelectionAllowed,
    isGuestCheckoutEnabled,
    selectType,
    isValidAllowedType,
  } = useRegistrationTypes()

  useEffect(() => {
    if (defaultCheckoutRegistrationType && isValidAllowedType(defaultCheckoutRegistrationType)) {
      selectType(defaultCheckoutRegistrationType)
    }
  }, [defaultCheckoutRegistrationType, isValidAllowedType, selectType])

  const setIsUseZipCodeRegistration = useCallback(
    (isZipCodeSelected: boolean) =>
      isZipCodeSelected ? selectType('patient-zip-code') : selectType('patient'),
    [selectType]
  )

  return (
    <Styled.CheckoutRegistration>
      <Styled.Content>
        <Subscribe>
          <Styled.Container>
            <Styled.Content>
              <Subscribe>
                {showGuestCheckoutTitle ? (
                  <Styled.Title>
                    Start Guest Checkout
                    <TitleHelper>Not already a customer? Be our guest</TitleHelper>
                  </Styled.Title>
                ) : (
                  <Styled.Title>
                    Create an Account
                    <TitleHelper>Not already a customer? Sign up now</TitleHelper>
                  </Styled.Title>
                )}
                <Styled.FormContainer>
                  {isSelectionAllowed && (
                    <RegistrationTypeSelector
                      allowPLPractitioner={false}
                      allowPLUnlicensed={false}
                      allowPractitioner={allowPractitioner}
                      allowPatient={allowPatient}
                      allowStudent={allowStudent}
                      selectedType={selectedType}
                      selectType={selectType}
                      disabled={submitting}
                    />
                  )}
                  {selectedType === 'practitioner' && (
                    <RegistrationFormPractitioner setSubmitting={setSubmitting} />
                  )}
                  {(selectedType === 'patient' || selectedType === 'patient-zip-code') && (
                    <RegistrationFormPatient
                      isGuestCheckoutEnabled={isGuestCheckoutEnabled}
                      allowPatientZipCode={allowPatientZipCode}
                      isUseZipCode={selectedType === 'patient-zip-code'}
                      setIsUseZipCode={setIsUseZipCodeRegistration}
                      submitButton={{ text: 'Create an Account', variant: 'contained' }}
                      onGuestCheckoutChange={setShowGuestCheckoutTitle}
                    />
                  )}
                  {selectedType === 'student' && (
                    <RegistrationFormStudent setSubmitting={setSubmitting} />
                  )}
                </Styled.FormContainer>
              </Subscribe>
            </Styled.Content>
          </Styled.Container>
        </Subscribe>
      </Styled.Content>
    </Styled.CheckoutRegistration>
  )
}
